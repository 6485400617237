'use client';

import { useParams, useSearchParams } from 'next/navigation';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';

import { type AuthProviderName } from '@shared/misc';
import { Loader } from '@shared/ui-components';
import { useMounted } from '@shared/utils';

import { usePathname, useRouter } from '../../i18n/i18n-navigation';
import { LOGIN_PATH, SIGNUP_PATH } from '../../utils/constants';
import { loginHandler } from '../../utils/helpers';

export const B2CFlowPageContent = ({ provider }: { provider?: AuthProviderName }) => {
  const parameters = useParams();
  const { status } = useSession();
  const isMounted = useMounted();
  const pathname = usePathname();
  const router = useRouter();
  const searchParameters = useSearchParams();
  const redirectAfterSessionErrorPath = searchParameters.get('redirect-uri-after-session-error');

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    let fullUrl = null;

    if (typeof window !== 'undefined') {
      fullUrl = pathname + window.location.search + window.location.hash;
    }

    const redirectToUrl = redirectAfterSessionErrorPath
      ? redirectAfterSessionErrorPath
      : pathname === LOGIN_PATH || pathname === SIGNUP_PATH
      ? '/'
      : fullUrl ?? pathname;

    if (status === 'authenticated') {
      router.push(redirectToUrl);
      return;
    }

    if (status === 'unauthenticated') {
      loginHandler({
        authorizationParameters: {
          referer: fullUrl ?? undefined,
          ui_locales: parameters.locale as string,
        },
        options: { redirectTo: redirectToUrl },
        provider,
      });
    }
  }, [
    isMounted,
    parameters.locale,
    pathname,
    provider,
    redirectAfterSessionErrorPath,
    router,
    status,
  ]);

  return <Loader />;
};
